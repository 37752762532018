// React Imports
import React from 'react'

// CSS Imports
import '../allPages.css'
import './homepage_Styles.css'
import "./Animated_Background/animated_Background.css"

// Icon Imports
import GitHub_Icon from './Homepage_Icons/github_Icon.png'
import Email_Icon from './Homepage_Icons/email_Icon.png'
import LinkedIn_Icon from './Homepage_Icons/linkedin_Icon.png'

// Hook Imports
import useAnimatedBackground from './Animated_Background/animated_Bg_Hook.js'
import usePagetitle from "../setPageTitle.js"


function Home() {
    usePagetitle("Home");
    useAnimatedBackground();

    return (
        <div className="page_Content">
            <h1 id="name">Zim Agu</h1>

            <div className="contact_icons"> 
                <li>
                    <a href="https://github.com/ZimmAgu" target="_blank">
                        <img src={GitHub_Icon} alt="Link to Zim Agu's Github Profile"/> 
                    </a>
                </li>


                <li>
                    <a href="mailto: zimmagu@gmail.com" target="_blank">
                        <img src={Email_Icon} alt="Link to Zim Agu's email address"/> 
                    </a>
                </li>


                <li>
                    <a href="https://www.linkedin.com/in/ZimmAgu/" target="_blank">
                        <img src={LinkedIn_Icon} alt="Link to Zim Agu's Linkedin Profile"/> 
                    </a>
                </li> 
            </div> 
        </div>
    )
}

export default Home