// React Imports
import React from 'react'

// CSS Imports
import '../allPages.css'
import "./resume_Page_Styles.css"

// Hook Imports
import useFadeEffect from "./fade_Effect_Hook.js"
import usePagetitle from "../setPageTitle.js"

// Icon Imports
import tech_Skills_Icon from "./Resume_Page_Icons/tech_Skills_Icon.png"
import graduation_Hat_Icon from "./Resume_Page_Icons/graduation_Hat_Icon.png"
import work_Experience_Icon from "./Resume_Page_Icons/work_Experience_Icon.png"


function Resume() {
    usePagetitle("About Me");
    useFadeEffect();

    return (
        <div className="page_Content">
            <div className="resume_container">
                {/* Section 1: Technical Skills */}
                <div className="resume_section" id="skills_Section">
                    <h1>TECHNICAL SKILLS</h1>
                    <div className="underline"></div>

                    <div className="section_display">
                        <div className="left_side">
                            <h2>Programming Languages</h2>
                            <ul>
                                <li>C</li>
                                <li>C#</li>
                                <li>C++</li>
                                <li>Java</li>
                                <li>Javascript</li>
                                <li>Python</li> 
                                <li>SQL</li>
                            </ul>

                            <h2>Web Design</h2>
                            <ul>
                                <li>CSS</li>
                                <li>HTML</li>
                            </ul>

                            <h2>Frameworks</h2>
                            <ul>
                                <li>ReactJS</li>
                                <li>REST API</li>
                                <li>Unity</li>
                            </ul>

                            <h2>Database Management</h2>
                            <ul>
                                <li>Amazon Web Services</li>
                                <li>Google Cloud Platform</li>
                            </ul>

                            <h2>Version Control</h2>
                            <ul>
                                <li>Git</li>
                                <li>Github</li>
                                <li>Perforce</li>
                            </ul>
                        </div>

                        <div className="right_side">
                            <img src={tech_Skills_Icon} alt="An icon of a computer with code on it"/>
                        </div>
                    </div>   
                </div>


                {/* Section 2: Education */}
                <div className="resume_section fadeIn" id="education_section">
                    <h1>EDUCATION</h1>
                    <div className="underline"></div>

                    <div className="section_display">
                        <div className="left_side">
                            <img src={graduation_Hat_Icon} alt="An icon of a computer with code on it"/>
                        </div>

                        <div className="right_side">
                            <h2>Formal Education</h2>

                            <ul>
                                <span>School: </span>
                                <li>University of Nevada, Reno</li>

                                <span>Degree:</span>
                                <li>Bachelor of Science (B.S.)</li>

                                <span>Major:</span>
                                <li>Computer Science & Engineering</li>

                                <span>Minor:</span>
                                <li>Mathematics</li>

                                <span>Graduation:</span>
                                <li>May 2022</li>

                                <span>Awards:</span>
                                <li>Millennium Scholarship</li>
                            </ul>

                        </div>
                    </div>
                </div>


                {/* Sectiom 3: Work Experience */}
                <div className="resume_section fadeIn" id="work_experience_section">
                    <h1>Work Experience</h1>
                    <div className="underline"></div>

                    <div className="section_display">
                        <div className="left_side">
                            <ul>
                                <li className="first_work_experience">
                                    <h3>Software Engineer Intern - Light & Wonder, Inc</h3>
                                    <span>Jan 2022 - May 2022</span>
                                    <span>Reno, NV, United States</span> 
                                </li>
                                {/* <li>
                                    <h3>Next Work Experience Goes Here</h3>
                                    <span>Later</span>
                                    <span>Austin, Tx, United States</span> 
                                </li> */}
                            </ul>
                        </div>

                        <div className="right_side">
                            <img src={work_Experience_Icon}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Resume