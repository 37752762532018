import React from 'react'
import { Link } from "react-router-dom";
import './navbar_Styles.css'

function Navbar() {
    return (
        <nav class="menu_bar">
            <ul >
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/projects">Projects</Link>
                </li>
                <li>
                    <Link to="/aboutme">Resume</Link>
                </li>
            </ul>  
        </nav>
    )
}

export default Navbar