import $ from 'jquery'; 
import {useEffect} from 'react';


const useFadeEffect = () => {
    useEffect(() => {
        const onScroll = (event) => {
            /* Check the location of each desired element */
            $('.fadeIn').each( function(i){
                
                var top_of_object = $(this).position().top;
                var bottom_of_window = $(window).scrollTop() + $(window).height();
                
                // Once the user scrolls to the top of the object, the fading effect starts
                if( bottom_of_window > top_of_object ){
                    $(this).animate({'opacity':'1'}, 2000);      
                }
                
            }); 
        };
          
        window.addEventListener('scroll', onScroll);
        
        return () => {
          window.removeEventListener('scroll', onScroll);
        }
      }, []);
}

export default useFadeEffect;