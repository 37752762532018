// React Imports
import React from 'react'

// Component Imports
import VideoModal from "./Project_Video_Modals/video_Modal"

// CSS Imports
import '../allPages.css'
import "./project_Page_Styles.css"

// Hook Imports
import { useState } from 'react'
import useBackgroundGradient from "./background_Gradient_Hook.js"
import usePagetitle from "../setPageTitle.js"

// Thumbail Imports
import ecommerce_project_thumbnail from "./Project_Thumbnails/ecommerce_project_thumbnail.png"
import algorithm_visualizer_thumbnail from "./Project_Thumbnails/algorithm_visualizer_thumbnail.png"
import super_mario_thumbnail from "./Project_Thumbnails/super_marios_thumbnail.png"
import storage_cloud_thumbnail from "./Project_Thumbnails/storage_cloud_thumbnail.png"
import snake_game_thumbnail from "./Project_Thumbnails/snake_game_thumbnail.png"


function Projects() {
    usePagetitle("Projects");
    useBackgroundGradient();

    const [video_Modal_Is_Open, video_Modal_State] = useState(false)
    const [modal_Name, video_Modal_Name_State] = useState("None")
    
    const open_Video_Modal = (projectName) => {
        video_Modal_State(true)
        video_Modal_Name_State(projectName)
    }


    return (
        <div className="page_Content">
            <p id="demoInfo">Hover over any project with mouse for: demo, source code, full project</p>
            <div class="project-grid">
                {/* Project 5: Ecommerce Application */}
                <div class="project">
                    <div class="project_Card">
                        <div class="thumbnail">
                            <img 
                                class="project_image" 
                                src={ecommerce_project_thumbnail} 
                                alt="Thumbnail for the Ecommerce Store project"
                            />
                            <h2 class="tooMuchText">Ecommerce Store</h2>
                        </div>

                        <div class="project_Information">
                            <div class="project_description">
                                <h3>Description:</h3>
                                <p>A fully functional ecommerce application built using Django and PostgreSQL. </p> 
                            </div>

                            <button class="infoButton" onClick={open_Video_Modal.bind(this, "Eccommerce Store Project")}>
                                <span>View Quick Demo</span> 
                            </button>

                            <a href="https://github.com/ZimmAgu/Ecommerce-Web-App" target="_blank">
                                <button class="infoButton">
                                    <span>View Source Code</span> 
                                </button>
                            </a>

                            <a href="https://zims-ecccommerce-app.herokuapp.com/" target="_blank">
                                <button class="infoButton">
                                    <span>View Full Project</span> 
                                </button>
                            </a>
                        </div>
                    </div>
                    {
                        video_Modal_Is_Open                         && 
                        modal_Name == "Eccommerce Store Project"    &&
                        <VideoModal 
                            modal_Open_State={video_Modal_State}
                            modal_Name_State={video_Modal_Name_State}
                            video_Title = "E-commerce Store Demo"
                            video_Source = "https://www.youtube.com/embed/elYXjAQ5QeE?autoplay=1"
                        />
                    }    
                </div>
                

                {/* Project 4: Aglorithm visualizer */}
                <div class="project">
                    <div class="project_Card">
                        <div class="thumbnail">
                            <img 
                                class="project_image" 
                                src={algorithm_visualizer_thumbnail} 
                                alt="Thumbnail for the Algorithm Visualize project"
                            />
                            <h2 class="tooMuchText">Algorithm Visualizer</h2>
                        </div>

                        <div class="project_Information">
                            <div class="project_description">
                                <h3>Description:</h3>
                                <p>This interactive appplication gives the user a visual representation of how graph traversal algorithms work.</p> 
                            </div>

                            <button class="infoButton" onClick={open_Video_Modal.bind(this, "Algorithm Visualizer Project")}>
                                <span>View Quick Demo</span> 
                            </button>

                            <a href="https://github.com/ZimmAgu/Algorithm-Visualizer" target="_blank">
                                <button class="infoButton">
                                    <span>View Source Code</span> 
                                </button>
                            </a>

                            <a href="https://zimsalgorithmvisuals.netlify.app/" target="_blank">
                                <button class="infoButton">
                                    <span>View Full Project</span> 
                                </button>
                            </a>
                        </div>
                    </div>
                    {
                        video_Modal_Is_Open                             && 
                        modal_Name == "Algorithm Visualizer Project"    &&
                        <VideoModal 
                            modal_Open_State={video_Modal_State}
                            video_Title = "Algorithm Visualizer Demo"
                            video_Source = "https://www.youtube.com/embed/cKkYP_UJ7tE?autoplay=1"
                        />
                    }    
                </div>


                {/* Project 3: Super Mario */}
                <div class="project">
                    <div class="project_Card">
                        <div class="thumbnail">
                            <img 
                                class="project_image" 
                                src={super_mario_thumbnail} 
                                alt="Thumbnail for the Super Mario project"
                            />
                            <h2 class="tooMuchText">Super Mario</h2>
                        </div>

                        <div class="project_Information">
                            <div class="project_description">
                                <h3>Description:</h3>
                                <p>A recreation of level 1-1 & level 1-2 from the iconic video game Super Mario Bros.</p> 
                            </div>

                            <button class="infoButton" onClick={open_Video_Modal.bind(this, "Super Mario Project")}>
                                <span>View Quick Demo</span> 
                            </button>

                            <a href="https://github.com/ZimmAgu/super-mario" target="_blank">
                                <button class="infoButton">
                                    <span>View Source Code</span> 
                                </button>
                            </a>

                            <a href="https://zims-mario-attempt.netlify.app/" target="_blank">
                                <button class="infoButton">
                                    <span>View Full Project</span> 
                                </button>
                            </a>
                        </div>
                    </div>
                    {
                        video_Modal_Is_Open && 
                        modal_Name == "Super Mario Project"  &&
                        <VideoModal 
                            modal_Open_State={video_Modal_State}
                            modal_Name_State={video_Modal_Name_State}
                            video_Title = "Super Mario Demo"
                            video_Source = "https://www.youtube.com/embed/uezTeBGEi54?autoplay=1"
                        />
                    }    
                </div>


                {/* Project 2: Storage Cloud */}
                <div class="project">
                    <div class="project_Card">
                        <div class="thumbnail">
                            <img 
                                class="project_image" 
                                src={storage_cloud_thumbnail} 
                                alt="Thumbnail for the Storage Cloud project"
                            />
                            <h2 class="tooMuchText">Storage Cloud</h2>
                        </div>

                        <div class="project_Information">
                            <div class="project_description">
                                <h3>Description:</h3>
                                <p>
                                    A storage cloud influenced by Google Drive and Dropbox. 
                                    You can create an account, then save and manage your data. 
                                    All of your information is encrypted to make sure it is secure.
                                </p> 
                            </div>

                            <button class="infoButton" onClick={open_Video_Modal.bind(this, "Storage Cloud Project")}>
                                <span>View Quick Demo</span> 
                            </button>

                            <a href="https://github.com/ZimmAgu/storage-cloud" target="_blank">
                                <button class="infoButton">
                                    <span>View Source Code</span> 
                                </button>
                            </a>

                            <a href="https://zims-storage-cloud.netlify.app/" target="_blank">
                                <button class="infoButton">
                                    <span>View Full Project</span> 
                                </button>
                            </a>
                        </div>
                    </div>
                    {
                        video_Modal_Is_Open && 
                        modal_Name == "Storage Cloud Project"  &&
                        <VideoModal 
                            modal_Open_State={video_Modal_State}
                            modal_Name_State={video_Modal_Name_State}
                            video_Title = "Storage Cloud Demo"
                            video_Source = "https://www.youtube.com/embed/3elGX8Kp1G4?autoplay=1"
                        />
                    }    
                </div>


                {/* Project 1: Snake Game */}
                <div class="project">
                    <div class="project_Card">
                        <div class="thumbnail">
                            <img 
                                class="project_image" 
                                src={snake_game_thumbnail} 
                                alt="Thumbnail for the Snake Game project"
                            />
                            <h2 class="tooMuchText">Snake Game</h2>
                        </div>

                        <div class="project_Information">
                            <div class="project_description">
                                <h3>Description:</h3>
                                <p>My first ever personal project. Use the arrow keys or WASD to control the snake.</p> 
                            </div>

                            <button class="infoButton" onClick={open_Video_Modal.bind(this, "Snake Game Project")}>
                                <span>View Quick Demo</span> 
                            </button>

                            <a href="https://github.com/ZimmAgu/javascript-snake-game" target="_blank">
                                <button class="infoButton">
                                    <span>View Source Code</span> 
                                </button>
                            </a>

                            <a href="https://zims-snake-game.netlify.app/" target="_blank">
                                <button class="infoButton">
                                    <span>View Full Project</span> 
                                </button>
                            </a>
                        </div>
                    </div>
                    {
                        video_Modal_Is_Open                 && 
                        modal_Name == "Snake Game Project"  &&
                        <VideoModal 
                            modal_Open_State={video_Modal_State}
                            modal_Name_State={video_Modal_Name_State}
                            video_Title = "Snake Game Demo"
                            video_Source = "https://www.youtube.com/embed/v3f2RZWLKvM?autoplay=1"
                        />
                    }    
                </div>
            </div>
        </div>
    )
}

export default Projects