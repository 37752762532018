import {useEffect} from 'react';

const useBackgroundGradient = () => {
    useEffect(() => {
        /*  Adds project page body styles to body of HTML 
            document when user naviagtes to homepage    */
        document.body.classList.add('project_Body');

        /*  Removes project page body styles from body of HTML  
            document when user naviagtes away from homepage */
        return () => {
            document.body.classList.remove('project_Body');
          };
    }, []);
}

export default useBackgroundGradient