import {useEffect} from 'react';

const useAnimatedBackground = () => {
    useEffect(() => {
        /*  Adds hompage body styles to body of HTML 
            document when user naviagtes to homepage    */
        document.body.classList.add('homepage_Body');

        /*  Removes hompage body styles from body of HTML  
            document when user naviagtes away from homepage */
        return () => {
            document.body.classList.remove('homepage_Body');
          };
    }, []);
}

export default useAnimatedBackground