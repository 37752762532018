import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from './Navbar/Navbar_Component';
import Home from './Pages/Home/Homepage';
import Projects from './Pages/Projects/ProjectsPage'
import Resume from "./Pages/Resume/ResumePage";


function App() {
  return (
    <Router>
      <div className="App">
          <Navbar />  
          <div className="Conent">
            <Routes>
              <Route path="/" element={<Home />}/>
              <Route path="/projects" element={<Projects />}/>
              <Route path="/aboutme" element={<Resume />}/>
            </Routes>  
          </div>
      </div>
    </Router>
  );
}

export default App;
