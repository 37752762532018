import React from 'react'
import './modal_Styles.css'
import './modal_Reactiveness.css'

function VideoModal(props) {
    const handleModalClosing = () => {
        props.modal_Open_State(false)
        props.modal_Name_State("None")
    }

    return (
        <div className="modal_Background">
            <div className='modal_Container'>
                <div className='modal_Title'>
                    <div className='modal_Title_Close_Button_Container'>
                        <button className='modal_Title_Close_Button' onClick={handleModalClosing}> X </button>
                    </div>
                    <h1>{props.video_Title}</h1>
                </div>
                <div className='modal_Body'>
                    <iframe 
                        src={props.video_Source}
                        title={props.video_Title}
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen
                        width
                    ></iframe>
                </div>
            </div>
        </div>
    )
}

export default VideoModal